<template>
  <DishFormPart>
    <template v-slot:title>
      {{ $t("components.menus.dishAssembly") }}
    </template>
    <template v-slot:content>
      <SortableList
        v-model="assembly"
        :label="$t(`components.menus.assemblyStepsTitle`)"
        :placeholder="$t(`components.menus.assemblyPlaceholder`)"
        :items="assembly"
        @update="update"
      />
    </template>
  </DishFormPart>
</template>

<script>
import DishFormPart from "@/components/menus/DishFormPart";
import SortableList from "@/components/common/SortableList";
import { mapActions } from "vuex";

export default {
  components: {
    DishFormPart,
    SortableList,
  },

  props: {
    currentDish: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      assembly: this.currentDish.assembly,
    };
  },

  created() {
    this.update();
  },

  methods: {
    ...mapActions("dishForm", ["updateAssembly"]),

    update() {
      this.updateAssembly(this.assembly);
    },
  },
};
</script>
