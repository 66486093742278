<template>
  <DishFormPart>
    <template v-slot:title>
      {{ $t("components.menus.dishPreparation") }}
    </template>
    <template v-slot:content>
      <div class="grid gap-4">
        <SortableList
          v-model="preparation"
          :label="$t(`components.menus.preparationStepsTitle`)"
          :placeholder="$t(`components.menus.preparationPlaceholder`)"
          :items="preparation"
          @update="update"
        />
        <VideoUpload
          v-model="preparationVideoInfo"
          :video-upload-function="uploadPreparationVideo"
          :remove-video-function="resetPreparationVideo"
          :error-message="preparationVideoUploadError"
          :reset-error-message="resetPreparationVideoUploadError"
          :is-uploading="isUploadingPreparationVideo"
          @update="updateVideoInfo"
        />
      </div>
    </template>
  </DishFormPart>
</template>

<script>
import DishFormPart from "@/components/menus/DishFormPart";
import SortableList from "@/components/common/SortableList";
import VideoUpload from "@/components/menus/VideoUpload";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    DishFormPart,
    SortableList,
    VideoUpload,
  },

  props: {
    currentDish: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      preparation: this.currentDish.preparation,
      preparationVideoUuid: this.currentDish.preparationVideo
        ? this.currentDish.preparationVideo.file.uuid
        : "",
      preparationVideoInfo: {
        url: this.currentDish.preparationVideo
          ? this.currentDish.preparationVideo.file.url
          : "",
        title: this.currentDish.preparationVideo
          ? this.currentDish.preparationVideo.title
          : "",
        presenter: this.currentDish.preparationVideo
          ? this.currentDish.preparationVideo.presenter
          : "",
      },
    };
  },

  computed: {
    ...mapGetters("dishForm", [
      "preparationVideoUploadError",
      "isUploadingPreparationVideo",
    ]),
  },

  created() {
    this.update();
    this.updatePreparationVideoUuid(this.preparationVideoUuid);
  },

  methods: {
    ...mapActions("dishForm", [
      "updatePreparation",
      "updatePreparationVideoInfo",
      "updatePreparationVideoUuid",
      "uploadPreparationVideo",
      "resetPreparationVideoUploadError",
      "resetPreparationVideo",
    ]),

    update() {
      this.updatePreparation(this.preparation);
      this.updatePreparationVideoInfo({
        title: this.preparationVideoInfo.title,
        presenter: this.preparationVideoInfo.presenter,
      });
    },

    updateVideoInfo(videoInfo) {
      this.preparationVideoInfo = videoInfo;
      this.update();
    },
  },
};
</script>
