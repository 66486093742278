<template>
  <div class="flex">
    <input
      ref="fileInput"
      type="file"
      class="hidden"
      @change="selectedFiles"
      @popFileSelector="popFileSelector"
    />
    <slot :popFileSelector="popFileSelector"></slot>
  </div>
</template>

<script>
export default {
  methods: {
    popFileSelector() {
      const fileInput = this.$refs.fileInput;
      if (fileInput) {
        fileInput.value = "";
        fileInput.click();
      }
    },
    selectedFiles(event) {
      this.$emit("selectedFiles", event.target.files);
    },
  },
};
</script>
