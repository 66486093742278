<template>
  <DishFormPart>
    <template v-slot:title>
      {{ $t("components.menus.dishAllergens") }}
    </template>
    <template v-slot:content>
      <SortableList
        v-model="allergens"
        :label="$t(`components.menus.allergensItemsTitle`)"
        :placeholder="$t(`components.menus.allergensPlaceholder`)"
        :items="allergens"
        :add-label="$t(`components.menus.addItem`)"
        @update="update"
      />
    </template>
  </DishFormPart>
</template>

<script>
import DishFormPart from "@/components/menus/DishFormPart";
import SortableList from "@/components/common/SortableList";
import { mapActions } from "vuex";

export default {
  components: {
    DishFormPart,
    SortableList,
  },

  props: {
    currentDish: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      allergens: this.currentDish.allergens || [],
    };
  },

  created() {
    this.update();
  },

  methods: {
    ...mapActions("dishForm", ["updateAllergens"]),

    update() {
      this.updateAllergens(this.allergens);
    },
  },
};
</script>
