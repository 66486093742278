<template>
  <div class="flex flex-col">
    <div class="mb-1 block text-sm font-medium text-neutral-700">
      {{ translations.label }}
    </div>
    <div v-if="filePreview && !coverImageUploadError" class="flex-1">
      <div
        :style="{ backgroundImage: `url(${filePreview})` }"
        class="rounded-sm bg-cover bg-center xl:h-full"
      >
        <div class="grid h-full bg-alpha-light-30">
          <div v-if="isUploadingCoverImage" class="flex items-end">
            <div class="relative h-2 w-full overflow-hidden bg-neutral-100">
              <span
                class="duration-250 absolute top-0 left-0 bottom-0 bg-primary-500 transition-all"
                :style="{ width: uploadProgress + '%' }"
              ></span>
            </div>
          </div>

          <div
            v-if="!isUploadingCoverImage"
            class="mb-64 flex items-start justify-end p-4 xl:mb-0"
          >
            <button
              class="cursor-pointer text-white hover:text-neutral-100"
              :title="translations.removeCoverImage"
              @click="removeFile"
            >
              <IconTrash />
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="showUploadState"
      class="flex flex-1 rounded-sm border-2 border-dashed border-neutral-50 bg-neutral-0"
    >
      <FileInput
        v-if="!coverImageUploadError"
        v-slot="{ popFileSelector }"
        class="flex flex-1 items-center justify-center p-4"
        @selectedFiles="uploadFile"
      >
        <BaseButton
          size="medium"
          variant="outline"
          outline-color="light"
          class="bg-white"
          :text="translations.upload"
          @buttonClick="popFileSelector"
        >
          <template #iconLeft>
            <IconCamera class="text-neutral-300" />
          </template>
        </BaseButton>
      </FileInput>

      <div
        v-if="coverImageUploadError"
        class="flex flex-1 flex-col items-center p-5"
      >
        <ErrorAlert :text="translations.uploadError" class="mb-6" />

        <div class="grid gap-2">
          <BaseButton
            size="medium"
            variant="outline"
            outline-color="light"
            class="bg-white"
            :text="translations.retry"
            @buttonClick="retryUpload"
          />

          <BaseButton
            size="medium"
            variant="outline"
            outline-color="light"
            class="bg-white"
            :text="translations.uploadNew"
            @buttonClick="removeFile"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileInput from "@/components/common/FileInput";
import ErrorAlert from "@/components/common/ErrorAlert";

export default {
  components: { FileInput, ErrorAlert },

  props: {
    currentImage: {
      type: Object,
      default: null,
    },
    isUploadingCoverImage: {
      type: Boolean,
      required: true,
    },
    coverImageUploadError: {
      type: String,
      default: null,
    },
    uploadCoverImage: {
      type: Function,
      required: true,
    },
    removeCoverImage: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      file: null,
      filePreview: null,
      uploadProgress: 0,

      translations: {
        label: this.$t(`components.menus.dishCoverImageLabel`),
        upload: this.$t(`components.menus.upload`),
        removeCoverImage: this.$t(`components.menus.removeCoverImage`),
        uploadError: this.$t(`components.menus.failedCoverImageUpload`),
        retry: this.$t(`components.menus.retryThisImage`),
        uploadNew: this.$t(`components.menus.uploadNew`),
      },
    };
  },

  computed: {
    showUploadState() {
      return !this.filePreview || this.coverImageUploadError;
    },
  },

  created() {
    if (this.currentImage) {
      this.filePreview = this.currentImage.url;
    }
  },

  methods: {
    async uploadFile([file]) {
      this.file = file;

      this.uploadCoverImage(this.file);

      this.renderFilePreview();

      this.fakeProgressTimer(this.file.size);
    },

    async retryUpload() {
      await this.uploadFile([this.file]);
    },

    renderFilePreview() {
      var reader = new FileReader();

      reader.onload = (e) => {
        this.filePreview = e.target.result;
      };

      reader.readAsDataURL(this.file);
    },

    removeFile() {
      this.file = null;
      this.filePreview = null;
      this.removeCoverImage();
    },

    fakeProgressTimer(fileSize) {
      this.uploadProgress = 0;

      const bytesPerSecond = 5e6; // 10 mb/sec
      const timeout = 100; // 100 ms

      let currentProgress = 0;
      let bytesTransferred = 0;

      let timer = setInterval(() => {
        bytesTransferred += bytesPerSecond / 10;
        currentProgress = Math.min((bytesTransferred / fileSize) * 100, 90);

        this.uploadProgress = currentProgress;

        if (bytesTransferred >= fileSize) {
          clearInterval(timer);
        }
      }, timeout);
    },
  },
};
</script>
