<template>
  <div>
    <div v-if="ingredients.length > 0" class="mb-3">
      <div class="mb-1 flex text-sm font-medium text-neutral-700">
        <div class="mr-1 flex-1 sm:mr-2">{{ translations.amount }}</div>
        <div class="mr-1 w-1/4 sm:mr-2">{{ translations.unit }}</div>
        <div class="w-1/2">{{ translations.ingredientName }}</div>
        <div class="hidden w-12 sm:flex"></div>
      </div>
      <div
        v-for="(ingredient, index) in ingredients"
        :key="index"
        class="animation-default relative mb-2 flex text-sm font-medium text-neutral-700 animation-fadeIn animation-once"
      >
        <div class="mr-1 flex-1 sm:mr-2">
          <TextField
            v-model="ingredient.amount"
            :placeholder="translations.amountPlaceholder"
            :pattern="floatValidationPattern"
            :maxlength="12"
            :type="'number'"
            @input="update"
          />
        </div>
        <div class="mr-1 w-1/4 sm:mr-2">
          <SelectField v-model="ingredient.unit" @input="update">
            <option value="-1" selected disabled>
              {{ translations.unitPlaceholder }}
            </option>
            <option
              v-for="unit in units"
              :key="unit.index"
              :value="unit.name"
              :selected="ingredient.unit === unit.name"
            >
              {{ unit.name }}
            </option>
          </SelectField>
        </div>
        <div class="w-1/2">
          <TextField
            v-model="ingredient.name"
            :placeholder="translations.ingredientNamePlaceholder"
            :maxlength="36"
            @input="update"
          />
        </div>
        <div class="absolute right-0 w-12 sm:static">
          <button
            class="focus:outline-none flex h-12 w-12 select-none items-center justify-center text-neutral-100 transition-all duration-200 hover:text-danger-500 focus:text-danger-500"
            @click="deleteRow(index)"
          >
            <IconDisband />
          </button>
        </div>
      </div>
    </div>
    <button
      v-if="ingredients.length < 20"
      class="focus:outline-none group inline-flex items-center text-sm text-neutral-600"
      @click="addRow"
    >
      <IconPlus
        class="text-neutral-300 transition-all duration-200 group-hover:text-neutral-600 group-focus:text-neutral-600"
      />
      <span>{{ translations.addIngredient }}</span>
    </button>
  </div>
</template>

<script>
import dishIngredientUnits from "@/data/dishIngredientUnits";
import { floatValidationPattern } from "@/validation/floatValidationPattern";

export default {
  props: {
    ingredients: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      units: dishIngredientUnits,

      floatValidationPattern,

      translations: {
        amount: this.$t("components.menus.amount"),
        amountPlaceholder: this.$t("components.menus.amountPlaceholder"),
        unit: this.$t("components.menus.unit"),
        unitPlaceholder: this.$t("components.menus.unitPlaceholder"),
        ingredientName: this.$t("components.menus.ingredientName"),
        ingredientNamePlaceholder: this.$t(
          "components.menus.ingredientNamePlaceholder",
        ),
        addIngredient: this.$t("components.menus.addIngredient"),
      },
    };
  },
  methods: {
    addRow() {
      this.ingredients.push({
        amount: "",
        unit: "",
        name: "",
      });
      this.update();
    },
    deleteRow(index) {
      this.ingredients.splice(index, 1);
      this.update();
    },
    update() {
      this.$emit("update");
    },
  },
};
</script>
