<template>
  <scrollactive
    :highlight-first-item="true"
    :modify-url="false"
    :duration="500"
    class="border mb-2 overflow-hidden rounded-sm border-neutral-50 bg-white"
  >
    <a
      v-for="item in navigationItems"
      :key="item.href"
      :href="item.href"
      class="scrollactive-item border-b relative flex h-14 items-center overflow-hidden border-neutral-50 pl-5 text-sm text-neutral-600 transition-all duration-200 last:border-0"
    >
      <span
        class="active-stripe absolute top-0 left-0 h-full w-1 origin-bottom scale-y-0 transform bg-primary-600 transition-transform duration-300"
      ></span>
      <span class="truncate">{{ item.text }}</span>
    </a>
  </scrollactive>
</template>

<script>
export default {
  data() {
    return {
      navigationItems: [
        {
          href: "#basic-information",
          text: this.$t("components.menus.dishBasicInformation"),
        },
        {
          href: "#ingredients",
          text: this.$t("components.menus.dishIngredients"),
        },
        {
          href: "#preparation",
          text: this.$t("components.menus.dishPreparation"),
        },
        {
          href: "#assembly",
          text: this.$t("components.menus.dishAssembly"),
        },
        {
          href: "#allergens",
          text: this.$t("components.menus.dishAllergens"),
        },
      ],
    };
  },
};
</script>

<style scoped>
.is-active {
  @apply font-medium text-neutral-900;
}
.is-active span:first-child {
  @apply origin-top scale-y-100;
}
</style>
