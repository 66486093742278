const dishIngredientUnits = [
  { name: "Tablespoon" },
  { name: "Liter" },
  { name: "Teaspoon" },
  { name: "Cup" },
  { name: "Kilogram" },
  { name: "gram" },
  { name: "piece" },
  { name: "milliliter" },
];
export default dishIngredientUnits;
