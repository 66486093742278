import { render, staticRenderFns } from "./DishFormNavigation.vue?vue&type=template&id=16ac230e&scoped=true&"
import script from "./DishFormNavigation.vue?vue&type=script&lang=js&"
export * from "./DishFormNavigation.vue?vue&type=script&lang=js&"
import style0 from "./DishFormNavigation.vue?vue&type=style&index=0&id=16ac230e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16ac230e",
  null
  
)

export default component.exports