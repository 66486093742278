<template>
  <div class="grid grid-cols-1 gap-8 lg:grid-cols-12">
    <div
      class="grid grid-cols-1 gap-4 md:gap-8 lg:order-1 lg:col-start-5 lg:col-end-13 xl:col-start-4 xl:col-end-13"
    >
      <ErrorAlert v-if="formError" :text="formError" />
      <DishBasicInformationFormPart
        id="basic-information"
        :current-dish="currentDish"
      />
      <DishIngredientsFormPart id="ingredients" :current-dish="currentDish" />
      <DishPreparationFormPart id="preparation" :current-dish="currentDish" />
      <DishAssemblyFormPart id="assembly" :current-dish="currentDish" />
      <DishAllergensFormPart id="allergens" :current-dish="currentDish" />
    </div>
    <div class="lg:col-start-1 lg:col-end-5 xl:col-start-1 xl:col-end-4">
      <div class="sticky top-0 grid gap-2 px-5 md:px-0">
        <DishFormNavigation class="hidden lg:block" />
        <slot name="dishFormBottom"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import DishBasicInformationFormPart from "@/components/menus/DishBasicInformationFormPart";
import DishIngredientsFormPart from "@/components/menus/DishIngredientsFormPart";
import DishPreparationFormPart from "@/components/menus/DishPreparationFormPart";
import DishAssemblyFormPart from "@/components/menus/DishAssemblyFormPart";
import DishAllergensFormPart from "@/components/menus/DishAllergensFormPart";
import DishFormNavigation from "@/components/menus/DishFormNavigation";
import ErrorAlert from "@/components/common/ErrorAlert";
import { mapGetters } from "vuex";

export default {
  components: {
    DishBasicInformationFormPart,
    DishIngredientsFormPart,
    DishPreparationFormPart,
    DishAssemblyFormPart,
    DishAllergensFormPart,
    DishFormNavigation,
    ErrorAlert,
  },

  props: {
    currentDish: {
      type: Object,
      required: false,
      default: () => {
        return {
          name: "",
          description: "",
          servings: "",
          prepTime: "",
          course: {
            uuid: "",
          },
          coverImageFileUuid: null,
          ingredients: [],
          preparation: [],
          assembly: [],
          allergens: [],
          coverImage: {
            url: null,
          },
          preparationVideo: null,
        };
      },
    },
  },

  computed: {
    ...mapGetters("dishForm", ["formError"]),
  },
};
</script>
