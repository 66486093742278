<template>
  <DishFormPart>
    <template v-slot:title>
      {{ $t("components.menus.dishIngredients") }}
    </template>
    <template v-slot:content>
      <IngredientSelector
        v-model="ingredients"
        :ingredients="ingredients"
        @update="update"
      />
    </template>
  </DishFormPart>
</template>

<script>
import DishFormPart from "@/components/menus/DishFormPart";
import IngredientSelector from "@/components/menus/IngredientSelector";
import { mapActions } from "vuex";

export default {
  components: {
    DishFormPart,
    IngredientSelector,
  },
  props: {
    currentDish: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ingredients: this.currentDish.ingredients,
    };
  },

  methods: {
    ...mapActions("dishForm", ["updateIngredients"]),

    update() {
      this.updateIngredients(this.ingredients);
    },
  },
};
</script>
