<template>
  <div
    class="border w-full rounded-sm border-danger-100 bg-danger-50 px-2 py-1 text-center text-sm font-medium text-danger-600"
  >
    <div class="flex items-center justify-center">
      <IconUploadFail class="mr-1 inline" />
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
