<template>
  <div>
    <FileInput
      v-if="!file && !errorMessage && !hasCurrentVideo"
      v-slot="{ popFileSelector }"
      @selectedFiles="uploadFile"
    >
      <BaseButton
        size="medium"
        variant="outline"
        :text="$t(`global.attachVideo`)"
        @buttonClick="popFileSelector"
      >
        <template #iconLeft>
          <IconVideoCamera class="text-neutral-300" />
        </template>
      </BaseButton>
    </FileInput>
    <div
      v-if="(file && !errorMessage) || hasCurrentVideo"
      class="border rounded-sm border-neutral-50 bg-neutral-0 text-sm"
    >
      <div class="relative flex h-14 items-center justify-between pl-4">
        <div
          v-if="isUploading"
          class="absolute top-0 left-0 h-full bg-neutral-50 transition-all duration-200"
          :style="{ width: uploadProgress + '%' }"
        ></div>
        <div
          :class="[
            'relative flex transition-all duration-200',
            { 'opacity-50': isUploading },
          ]"
        >
          <IconFileVideo class="mr-2 flex-shrink-0" />
          <div v-if="file">{{ file.name }}</div>
          <div v-else>{{ video.url.split("/").pop() }}</div>
        </div>
        <div class="relative">
          <button
            v-if="!isUploading"
            class="focus:outline-none flex h-12 w-12 select-none items-center justify-center text-neutral-100 transition-all duration-200 hover:text-danger-500 focus:text-danger-500"
            @click="deleteFile"
          >
            <IconDisband />
          </button>
        </div>
      </div>
      <div class="border-t grid grid-cols-2 gap-4 border-neutral-50 p-4">
        <TextField
          v-model="video.title"
          :label="$t(`components.menus.videoTitle`)"
          :placeholder="$t(`components.menus.videoTitlePlaceholder`)"
          :maxlength="maxlength"
          @input="update"
        />
        <TextField
          v-model="video.presenter"
          :label="$t(`components.menus.videoPresenter`)"
          :placeholder="$t(`components.menus.videoPresenterPlaceholder`)"
          :maxlength="maxlength"
          @input="update"
        />
      </div>
    </div>
    <div
      v-if="errorMessage"
      class="border flex flex-wrap justify-between rounded-sm border-neutral-50 bg-neutral-0 p-3 text-sm"
    >
      <div class="flex items-center text-danger-600">
        <IconUploadFail class="mr-2 flex-shrink-0" />
        <div>{{ errorMessage }}</div>
      </div>
      <div>
        <BaseButton
          size="small"
          variant="outline"
          :text="$t(`global.cancel`)"
          @buttonClick="dismissError"
        >
        </BaseButton>
      </div>
    </div>
  </div>
</template>
<script>
import FileInput from "@/components/common/FileInput";
export default {
  components: {
    FileInput,
  },

  props: {
    value: {
      type: Object,
      required: true,
    },
    videoUploadFunction: {
      type: Function,
      required: true,
    },
    removeVideoFunction: {
      type: Function,
      required: true,
    },
    resetErrorMessage: {
      type: Function,
      required: true,
    },
    errorMessage: {
      type: String,
      required: false,
      default: null,
    },
    isUploading: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      file: null,
      uploadProgress: 0,
      maxlength: 55,
      video: { ...this.value },
    };
  },

  computed: {
    hasCurrentVideo() {
      return this.value.url !== "";
    },
  },

  methods: {
    async uploadFile([file]) {
      this.video = {
        url: "",
        title: "",
        presenter: "",
      };
      this.file = file;
      this.videoUploadFunction(this.file);
      this.fakeProgressTimer(this.file.size);
    },
    deleteFile() {
      this.file = null;
      this.video = {
        url: "",
        title: "",
        presenter: "",
      };

      this.removeVideoFunction();
      this.update();
    },
    dismissError() {
      this.resetErrorMessage();
      this.deleteFile();
    },
    update() {
      this.$emit("update", {
        url: this.video.url,
        title: this.video.title,
        presenter: this.video.presenter,
      });
    },

    fakeProgressTimer(fileSize) {
      this.uploadProgress = 0;

      const bytesPerSecond = 5e6; // 10 mb/sec
      const timeout = 100; // 100 ms

      let currentProgress = 0;
      let bytesTransferred = 0;

      let timer = setInterval(() => {
        bytesTransferred += bytesPerSecond / 10;
        currentProgress = Math.min((bytesTransferred / fileSize) * 100, 100);

        this.uploadProgress = currentProgress;

        if (bytesTransferred >= fileSize) {
          clearInterval(timer);
        }
      }, timeout);
    },
  },
};
</script>
