<template>
  <DishFormPart>
    <template v-slot:title>
      {{ $t("components.menus.dishDetails") }}
    </template>
    <template v-slot:content>
      <div class="row-gap-6 col-gap-16 mb-6 grid grid-cols-1 xl:grid-cols-2">
        <div>
          <CoverImageUpload
            :current-image="currentDish.coverImage"
            :is-uploading-cover-image="isUploadingCoverImage"
            :cover-image-upload-error="coverImageUploadError"
            :upload-cover-image="uploadCoverImage"
            :remove-cover-image="removeCoverImage"
            class="h-full xl:mb-0"
          />
        </div>
        <div class="grid gap-6">
          <TextField
            v-model="name"
            :label="translations.nameLabel"
            :placeholder="translations.namePlaceholder"
            @input="update"
          />
          <TextArea
            v-model="description"
            :label="translations.descriptionLabel"
            :placeholder="translations.descriptionPlaceholder"
            :maxlength="230"
            :counter="true"
            @input="update"
          />
        </div>
      </div>
      <div class="row-gap-6 col-gap-16 grid grid-cols-1 xl:grid-cols-2">
        <div>
          <div class="grid grid-cols-2 gap-8">
            <TextField
              v-model="servings"
              :label="translations.servingsLabel"
              :placeholder="translations.servingsPlaceholder"
              :pattern="integerValidationPattern"
              :type="'number'"
              @input="update"
            />
            <TextField
              v-model="prepTime"
              :label="translations.prepTimeLabel"
              :placeholder="translations.prepTimePlaceholder"
              :pattern="integerValidationPattern"
              :type="'number'"
              @input="update"
            />
          </div>
        </div>
        <div>
          <div class="grid grid-cols-2 gap-8">
            <SelectField
              v-model="courseUuid"
              :label="translations.courseLabel"
              @input="update"
            >
              <option
                v-for="dishCourse in dishCourses"
                :key="dishCourse.uuid"
                :value="dishCourse.uuid"
                :selected="courseUuid === dishCourse.uuid"
              >
                {{ dishCourse.name }}
              </option>
            </SelectField>

            <SelectField
              v-model="cutleryType"
              :label="translations.cutleryLabel"
              @input="update"
            >
              <option
                v-for="cutleryOption in dishCutlery"
                :key="cutleryOption.id"
                :value="cutleryOption.id"
                :selected="cutleryType === cutleryOption.id"
              >
                {{ cutleryOption.name }}
              </option>
            </SelectField>
          </div>
        </div>
      </div>
    </template>
  </DishFormPart>
</template>

<script>
import DishFormPart from "@/components/menus/DishFormPart";
import CoverImageUpload from "@/components/menus/CoverImageUpload";

import { mapGetters, mapActions } from "vuex";
import { integerValidationPattern } from "@/validation/integerValidationPattern";

export default {
  components: { DishFormPart, CoverImageUpload },

  props: {
    currentDish: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      name: this.currentDish.name,
      description: this.currentDish.description,
      servings: this.currentDish.servings,
      prepTime: this.currentDish.prepTime,
      courseUuid: this.currentDish.course.uuid,
      cutleryType: this.currentDish.cutlery,

      file: null,

      integerValidationPattern,

      translations: {
        nameLabel: this.$t("components.menus.dishNameLabel"),
        namePlaceholder: this.$t("components.menus.dishNamePlaceholder"),
        descriptionLabel: this.$t("components.menus.dishDescriptionLabel"),
        descriptionPlaceholder: this.$t(
          "components.menus.dishDescriptionPlaceholder",
        ),
        servingsLabel: this.$t("components.menus.dishServingsLabel"),
        servingsPlaceholder: this.$t(
          "components.menus.dishServingsPlaceholder",
        ),
        prepTimeLabel: this.$t("components.menus.dishPrepTimeLabel"),
        prepTimePlaceholder: this.$t(
          "components.menus.dishPrepTimePlaceholder",
        ),
        courseLabel: this.$t("components.menus.dishCourseLabel"),
        cutleryLabel: this.$t("components.menus.cutleryLabel"),
      },
    };
  },

  computed: {
    ...mapGetters({
      dishCourses: "dish/dishCourses",
      isUploadingCoverImage: "dishForm/isUploadingCoverImage",
      coverImageUploadError: "dishForm/coverImageUploadError",
      dishCutlery: "dish/dishCutlery",
    }),
  },

  created() {
    this.update();

    if (this.currentDish.coverImage) {
      this.updateCoverImageFileUuid(this.currentDish.coverImage.uuid);
    }
  },

  methods: {
    ...mapActions("dishForm", [
      "updateBasicInformation",
      "uploadCoverImage",
      "removeCoverImage",
      "updateCoverImageFileUuid",
    ]),

    update() {
      this.updateBasicInformation({
        name: this.name,
        description: this.description,
        servings: this.servings,
        prepTime: this.prepTime,
        courseUuid: this.courseUuid,
        cutlery: this.cutleryType,
      });
    },
  },
};
</script>
