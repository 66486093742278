<template>
  <div>
    <div
      v-if="editedItems.length > 0"
      class="mb-2 text-sm font-medium text-neutral-700"
    >
      {{ label }}
    </div>
    <draggable
      v-if="editedItems.length > 0"
      v-model="editedItems"
      animation="300"
      class="draggable-list mb-4 grid gap-2"
      @change="update"
    >
      <div
        v-for="(item, index) in editedItems"
        :key="index"
        class="draggable-item flex"
      >
        <textarea
          v-model="item.text"
          :placeholder="placeholder"
          class="border flex-grow focus:outline-none w-full resize-none border-neutral-100 px-4 py-3 text-sm text-neutral-900 placeholder-neutral-400 focus:border-neutral-200"
          rows="2"
          @input="update"
        ></textarea>
        <div class="flex items-center">
          <button
            class="focus:outline-none flex h-12 w-12 select-none items-center justify-center text-neutral-100 transition-all duration-200 hover:text-danger-500 focus:text-danger-500"
            @click="deleteRow(index)"
          >
            <IconDisband />
          </button>
        </div>
      </div>
    </draggable>
    <button
      class="focus:outline-none group inline-flex items-center text-sm text-neutral-600"
      @click="addRow"
    >
      <IconPlus
        class="text-neutral-300 transition-all duration-200 group-hover:text-neutral-600 group-focus:text-neutral-600"
      />
      <span>{{ addLabel || $t("components.menus.addStep") }}</span>
    </button>
  </div>
</template>

<script>
import Draggable from "vuedraggable";

export default {
  components: {
    Draggable,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    addLabel: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      editedItems: [],
    };
  },
  watch: {
    items: function (newVal) {
      this.editedItems = newVal;
    },
  },
  created() {
    this.editedItems = this.items;
  },
  methods: {
    addRow() {
      this.items.push({
        text: "",
      });
      this.update();
    },
    deleteRow(index) {
      this.items.splice(index, 1);
      this.update();
    },
    update() {
      this.$emit("update", this.editedItems);
    },
  },
};
</script>

<style scoped>
.draggable-item {
  counter-increment: step-counter;
}
.draggable-item::before {
  content: counter(step-counter) ".";
  @apply border cursor-move border-r-0 border-neutral-100 bg-neutral-0 p-4 pt-3 text-sm font-medium text-neutral-900;
}
</style>
